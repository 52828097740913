<template>
    <div class="dirr">

        <v-container style="max-width:1261px !important">
            <div class="panl-title">
                <img :src="$vuetify.theme.dark ? '/assets/img/z-mohammed_dark.svg' : '/assets/img/z-mohammed.svg'" />
                <router-link :to="'/media?lang='+$i18n.locale"
                    style="text-decoration: none !important;">
                    <div class="tl panl-title-text d-inline"> {{ $t("index_videos") }}</div>
                </router-link>
            </div>



            <v-layout row wrap mt-5>

                <v-flex xs12 sm6 v-if="loading" class="vid_h_1 ">
                    <v-skeleton-loader type="image" max-height="400">
                    </v-skeleton-loader>
                    <v-skeleton-loader type="image" max-height="500">
                    </v-skeleton-loader>
                </v-flex>

                <v-flex xs12 v-for="video in res.slice(0,1)" sm6 class="vid_h_1" v-else-if="!loading && !video_main"
                    :key="video" style="">
                    <!-- <video ref="videoPlayer" class="video-js" width="690"  :poster="'https://alkafeel.net/videos/mcroped11/473/'+video.photo"  height="390">
                            <source src="https://static1.alkafeel.net/videos/9c07bbaa/9c07bbaa.mp4" type='video/mp4'>
                    <source src="https://static1.alkafeel.net/videos/9c07bbaa/9c07bbaa.mp4" type='video/webm'>
                        </video>  -->




                    <router-link :to="'/media/'+video.request+'?lang='+$i18n.locale" style="text-decoration: none;">
                        <div class="vid_min_first_home">
                            <div class="video_icon_home">

                                <v-btn icon>
                                    <img src="assets/img/play_icon.svg" />
                                </v-btn>
                            </div>
                            <img style="width:100%;height:100%;border-radius: 4px;"
                                :src="'https://alkafeel.net/videos/mcroped11/473/'+video.photo" />
                            <div class="video_home_title ">
                                <p>
                                    <img src="assets/img/z-ali.svg" /> <span class="tl">{{video.title}} </span> </p>
                            </div>





                        </div>


                    </router-link>
                </v-flex>

                <v-flex xs12 class="vid_h_1" sm6 style=" " v-else-if="video_main">
                    <video ref="videoPlayer" class="video-js vid_home_los"></video>


                </v-flex>



                <v-flex sm6 md6 class="vid_h_2">


                    <v-layout class="sec_vei_trc" row wrap style="    text-decoration: none;;    margin-top: 1px;">
                        <template v-if="loading">
                            <v-flex xs6 v-for="video in 2" :key="video" class="video_item_homes">
                                <div style="width:100%;;position: relative;">
                                    <v-skeleton-loader type="image" max-height="150">
                                    </v-skeleton-loader>

                                    <v-skeleton-loader type="image" max-height="150">
                                    </v-skeleton-loader>
                                </div>
                            </v-flex>
                        </template>
                        <template v-else>
                            <v-flex xs6 md6 sm12 v-for="video in res.slice(1,2)" :key="video" class="video_item_homes">


                                <div style="width:100%;height:300px;position: relative;">

                                    <router-link :to="'/media/'+video.request+'?lang='+$i18n.locale"
                                        style="    text-decoration: none;height:300px">
                                        <div class="video_icon_home" style="top: 42% !important;">

                                            <img src="assets/img/play_icon.svg" />
                                        </div>

                                        <img style="width:100%;height:100%;border-radius: 4px;"
                                            :src="'https://alkafeel.net/videos/mcroped119/380/'+video.photo" />

                                        <div class="video_home_title">
                                            <p>
                                                <img src="assets/img/z-ali.svg" /> <span class="tl">{{video.title}}
                                                </span> </p>
                                        </div>





                                    </router-link>
                                </div>

                            </v-flex>
                        </template>

                        <template v-if="!loading">
                            <v-flex xs6 v-for="video in res.slice(2,3)" :key="video"
                                class="video_item_homes video_item_homes_second  ">

                                <div style="width:100%;height:300px;position: relative;">

                                    <router-link :to="'/media/'+video.request+'?lang='+$i18n.locale"
                                        style="    text-decoration: none;height:300px">

                                        <div class="video_icon_home" style="top: 42% !important;">

                                            <img src="assets/img/play_icon.svg" />
                                        </div>


                                        <img style="width:100%;height:100%;border-radius: 4px;"
                                            :src="'https://alkafeel.net/videos/mcroped119/380/'+video.photo" />
                                        <div class="video_home_title ">
                                            <p>
                                                <img src="assets/img/z-ali.svg" /> <span class="tl">{{video.title}}
                                                </span> </p>
                                        </div>




                                    </router-link>

                                </div>
                            </v-flex>
                        </template>
                    </v-layout>








                    <v-layout row wrap class="d-sm-none d-md-flex vid_swip_desk">
                        <v-flex xs12 class="video_item_homes" style="max-width:808px">
                            <swiper class="swiper " :options="swiperOptionsx" :navigation="true"
                                style="margin-top: 36px;">
                                <div class="swiper-paginations"></div>

                                <template v-if="loading">
                                    <swiper-slide class="swiper-slide" v-for="video in 9" :key="video">
                                        <div>
                                            <v-skeleton-loader type="image" height="100"></v-skeleton-loader>
                                        </div>
                                    </swiper-slide>
                                </template>

                                <template v-else>
                                    <swiper-slide class="swiper-slide" v-for="video in res.slice(3,13)" :key="video">
                                        <div>
                                            <router-link :to="'/media/'+video.request+'?lang='+$i18n.locale"
                                                style="    text-decoration: none;">

                                                <img :src="'https://alkafeel.net/videos/mcroped95/180/'+video.photo"
                                                    alt="" style="height: 100px;width: 100%;    border-radius: 4px;">
                                                <div class="video_icon_home_swip">



                                                    <img src="assets/img/video_icon_small.png "
                                                        style="width: 100%;eight: 100%;" />



                                                </div>

                                                <div class="vid_swb_home_tit tl">{{video.title|subStrArtTitle}} </div>
                                            </router-link>
                                        </div>


                                    </swiper-slide>

                                </template>





                                <!-- <div v-if="!loading" class="swiper-button-prev" slot="button-prev"></div>
                                <div v-if="!loading" class="swiper-button-next" slot="button-next"></div> -->
                                <div class="swiper-pagination  " slot="pagination"></div>
                            </swiper>

                        </v-flex>

                    </v-layout>






                </v-flex>


                <v-layout row wrap class="d-none d-sm-flex d-md-none vid_swip_desk">
                    <v-flex xs12 class="video_item_homes" style="max-width:808px">
                        <swiper class="swiper " :options="swiperOptionsx" :navigation="true" style="margin-top: 36px;">
                            <div class="swiper-paginations"></div>

                            <template v-if="loading">
                                <swiper-slide class="swiper-slide" v-for="video in 9" :key="video">
                                    <div>
                                        <v-skeleton-loader type="image" height="100"></v-skeleton-loader>
                                    </div>


                                </swiper-slide>
                            </template>

                            <template v-else>
                                <swiper-slide class="swiper-slide" v-for="video in res.slice(3,13)" :key="video">
                                    <div>
                                        <router-link :to="'/media/'+video.request+'?lang='+$i18n.locale"
                                            style="    text-decoration: none;">

                                            <img :src="'https://alkafeel.net/videos/mcroped95/180/'+video.photo" alt=""
                                                style="height: 100px;width: 100%;    border-radius: 4px;">
                                            <div class="video_icon_home_swip">



                                                <img src="assets/img/video_icon_small.png "
                                                    style="width: 100%;eight: 100%;" />



                                            </div>

                                            <div class="vid_swb_home_tit tl">{{video.title|subStrArtTitle}} </div>
                                        </router-link>
                                    </div>


                                </swiper-slide>

                            </template>





                            <!-- <div v-if="!loading" class="swiper-button-prev" slot="button-prev"></div>
                                <div v-if="!loading" class="swiper-button-next" slot="button-next"></div> -->
                            <div class="swiper-pagination " slot="pagination"></div>
                        </swiper>

                    </v-flex>

                </v-layout>








            </v-layout>



            <v-layout row wrap class="d-flex d-sm-none vid_swip_hh">
                <v-flex xs12 class="video_item_homes" style="    margin-right: 0px;">
                    <swiper class="swiper " :options="swiperOptionsM" style="margin-top:31px;">
                        <div class="swiper-paginations"></div>
                        <swiper-slide class="swiper-slide" v-for="video in res.slice(1,13)" :key="video">
                            <div>
                                <router-link :to="'/media/'+video.request+'?lang='+$i18n.locale"
                                    style="    text-decoration: none;">
                                    <img :src="'https://alkafeel.net/videos/mcroped95/365/'+video.photo" alt=""
                                        style="height: 90px;width: 100%;    border-radius: 4px;">

                                    <div class="video_icon_home_swip">



                                        <img src="assets/img/video_icon_small.png " style="width: 100%;eight: 100%;" />



                                    </div>


                                    <div class="vid_swb_home_tit tl">{{video.title|subStrArtTitle}} </div>
                                </router-link>
                            </div>


                        </swiper-slide>
                        <div class="swiper-pagination  " slot="pagination"></div>
                        <!-- <div class="swiper-button-prev" slot="button-prev"></div>
                        <div class="swiper-button-next" slot="button-next"></div> -->

                    </swiper>

                </v-flex>

            </v-layout>


        </v-container>

    </div>
</template>

<script>
    // import videojs from 'video.js/dist/alt/video.core.novtt.js';
    // import 'video.js/dist/video-js.min.css';

    export default {
        name: "VideoPlayer",
        //   name: 'carrousel',
        components: {

        },
        filters: {
            subStrArtTitle: function (string) {

                if (string != null) {
                    return string.substring(0, 50) + ' ... ';
                }

            },
        },

        data() {
            return {
                res: [],
                video_main: false,
                photoCats: [],
                player: null,
                videoOptions: {
                    autoplay: true,
                    autoplay: {
                        delay: 21600,
                        disableOnInteraction: true,
                    },
                    controls: true,

                    sources: [{
                        src: "",
                        type: "application/x-mpegURL"
                    }]
                },
                swiperOptionsx: {
                    autoplay: true,

                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true
                    },


                    width: 788,
                    slidesPerView: 4,
                    spaceBetween: 23,
                    loading: true,

                    freeMode: false,
                    keyboard: true,
                    breakpoints: {

                        640: {
                            slidesPerView: 4,


                        },
                        360: {
                            slidesPerView: 4,


                        },

                        768: {
                            slidesPerView: 4,


                        },
                        1024: {
                            slidesPerView: 4,
                            // spaceBetween: 23,

                        },
                    }



                },


                swiperOptionsM: {
                    autoplay: {delay: 7000},
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true
                    },
                    width: 788,
                    slidesPerView: 4,
                    spaceBetween: 23,
                    loading: true,
                    freeMode: false,
                    breakpoints: {
                        640: {
                            slidesPerView: 4
                        },
                        
                    }



                },

            }
        },



        created() {
            // this.initialize();
            this.initializes();

        },
        methods: {
            showVideo(video) {


                this.videoOptions.sources[0].src = 'https://static1.alkafeel.net/videos/' + video.request + '/720/' +
                    video.request + '-720.m3u8';
                // alert(this.videoOptions.sources[0].src);
                this.video_main = true;
                this.inivideoplayer();

            },
            // play_player() {
            //     this.player = videojs(this.$refs.videoPlayer, this.videoOptions, function onPlayerReady() {})
            // },



            inivideoplayer() {
                setTimeout(() => this.play_player(), 300);
            },
            desvideoplayer() {
                if (this.player) {
                    this.player.dispose()
                }
            },


            base64(code) {
                return btoa(code);

            },


            initializes() {
                this.loading = true;
                this.$axios.get("videos/ByLimit/9", {})
                    .then(res => {
                        this.loading = false;
                        this.res = res.data.Data;
                        // console.log(res.data);

                        //this.player = videojs(this.$refs.videoPlayer, this.videoOptions, function onPlayerReady() {})
                    })
                    .catch(() => {
                        //    this.loading = false;
                    });
            },
        }
    }
</script>

<style>
    .swiper-pagination {
        position: relative !important;
        bottom: 0px !important;
        top: 2px !important;
    }
</style>